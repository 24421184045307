import React from 'react'
import Faqcom from '../components/Faqcom';

const FaqPage = () => {
  return (
    <div>
      <Faqcom />
    </div>
  )
}

export default FaqPage;